<template>
  <div>
    <Title title="Student Resources" />
    <DocumentList :documentListItems="resourcesListItems" />
  </div>
</template>

<script>
import DocumentList from '@/components/DocumentList.vue'
import Title from '@/components/Title.vue'

export default {
  components: {
    DocumentList,
    Title
  },
  data () {
    return {
      resourcesListItems: [
        { router: true, documentLink: '/student-faq', iconGroup: 'fa', icon: 'file-alt', documentName: 'Student FAQ' },
        { router: true, documentLink: '/flightscope-documentation', iconGroup: 'fa', icon: 'file-alt', documentName: 'Flightscope Aviation Documentation' },
        { router: true, documentLink: '/homework-sheets', iconGroup: 'fa', icon: 'file-signature', documentName: 'Homework Sheets' },
        { router: true, documentLink: '/videos', iconGroup: 'fa', icon: 'video', documentName: 'Videos' },
        { router: false, documentLink: 'https://vfrg.flightscopeaviation.com.au/visual-flight-rules-guide.pdf', iconGroup: 'fa', icon: 'file', documentName: 'Visual Flight Rules Guide (VFRG) (pdf)' },
        { router: false, documentLink: 'https://www.airservicesaustralia.com/naips/Account/Logon', iconGroup: 'fa', icon: 'file', documentName: 'Airservices NAIPS' },
        { router: false, documentLink: 'https://www.airservicesaustralia.com/aip/aip.asp', iconGroup: 'fa', icon: 'file', documentName: 'Airservices Aeronautical Information Package (AIP)' }
      ]
    }
  }
}
</script>
